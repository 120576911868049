import "./../styles/authorTemplate.scss"
import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { AUTHORS } from "../utils/constants"
import { graphql } from "gatsby"
import withAppContext from "../contexts/app.context"
import { Helper } from "../utils/helper"
import TwitterSvg from "../components/svg/twitter"
import LinkedinSvg from "../components/svg/linkedin"

const AuthorTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  const isAuthorLinked = AUTHORS.includes(frontmatter.author)

  let authorUrl
  if (isAuthorLinked) {
    authorUrl = Helper.getAuthorUrl(frontmatter.author)
  }

  const relativeUrl = authorUrl

  return (
    <Layout>
      <div className="container">
        <SEO
          title={`${frontmatter.title} | Volterra`}
          pageUrl={relativeUrl}
          description={frontmatter.excerpt}
          keywords={frontmatter.metaKeywords}
          imageName={
            !!frontmatter.metaImage
              ? frontmatter.metaImage.publicURL
              : undefined
          }
          twImageName={
            !!frontmatter.metaImageTw
              ? frontmatter.metaImageTw.publicURL
              : undefined
          }
          isArticle={true}
          author={frontmatter.author}
        />

        <article className="article__outer">
          <div className="author">
            {frontmatter.metaImage && (
              <div className="author__img">
                <img
                  src={frontmatter.metaImage.publicURL}
                  alt={frontmatter.author}
                />
              </div>
            )}
            <div className="author__body">
              <h1 className="article__title">{frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
              <div className="author__social">
                {frontmatter.twitter && (
                  <a
                    aria-label="Twitter link"
                    className="share__icon"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={frontmatter.twitter}>
                    <TwitterSvg />
                  </a>
                )}
                {frontmatter.linkedIn && (
                  <a
                    aria-label="Linkedin link"
                    className="share__icon"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={frontmatter.linkedIn}>
                    <LinkedinSvg />
                  </a>
                )}
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      fields {
        category
      }
      frontmatter {
        title
        author
        excerpt
        slug
        metaTitle
        metaKeywords
        metaImage {
          publicURL
        }
        linkedIn
        twitter
      }
    }
  }
`

export default withAppContext(AuthorTemplate)
